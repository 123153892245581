let publicPath = "/";
const helper = {
  elExists: (arr) => {
    let isExist = false;
    arr.forEach((el) => {
      if (document.querySelectorAll(el).length) {
        isExist = true;
      }
    });
    return isExist;
  },
  elExist: (id) => {
    return document.getElementById(id) != null ? true : false;
  },
  arrIsNull: (arr) => {
    return arr.length > 0 ? true : false;
  },
  addScript: (url, cdn) => {
    let scriptEl = document.createElement("script");
    scriptEl.src = cdn !== undefined ? publicPath + url : url;
    document.body.appendChild(scriptEl);
  },
  addCSS: (url, cdn) => {
    let cssEl = document.createElement("link");
    cssEl.href = cdn !== undefined ? publicPath + url : url;
    cssEl.media = "all";
    cssEl.rel = "stylesheet";
    cssEl.type = "text/css";
    let linkEls = document.querySelectorAll("link");
    linkEls.forEach((linkEl) => {
      if (linkEl.href.search("style.min.css") > 0) {
        document.head.insertBefore(cssEl, linkEl);
      }
    });
  },
  removeCurrentClassArr: (arr, className) => {
    arr.forEach((element) => {
      element.classList.remove(className);
    });
  },
  showWhenScroll: function (el, offsetY) {
    if (el) {
      if (window.scrollX > offsetY) {
        el.classList.add("show");
      } else {
        el.classList.remove("show");
      }
    }
  },
  formatCurrency: function (n, currency) {
    return (
      parseInt(n)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + currency
    );
  },
};
export default helper;
